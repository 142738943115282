import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import elementResizeDetectorMaker from 'element-resize-detector'
import 'element-ui/lib/theme-chalk/index.css';
import AOS from '@/assets/script/AOS/js/aos'
import 'aos/dist/aos.css'
import '@/assets/scss/global.scss'
import VueCoreVideoPlayer from 'vue-core-video-player'
import animated from "animate.css"
Vue.use(VueCoreVideoPlayer)
Vue.use(ElementUI);
Vue.use(animated);
Vue.config.productionTip = false
Vue.prototype.$erd = elementResizeDetectorMaker()
Vue.prototype.$aos = AOS;
AOS.init({
  once:true,
});
//AOS.refresh();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
