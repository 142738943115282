/**
 * Set or remove aos-animate class
 * @param {node} el         element
 * @param {int}  top        scrolled distance
 * @param {void} once
 */
const setState = function (el, top, once) {
  const attrOnce = el.node.getAttribute('data-aos-once');
  //console.log(top,el.position)
  if (top > el.position) {
    el.node.classList.add('aos-animate');
  } else if (typeof attrOnce !== 'undefined') {
    if (attrOnce === 'false' || (!once && attrOnce !== 'true')) {
      el.node.classList.remove('aos-animate');
    }
  }
};


/**
 * Scroll logic - add or remove 'aos-animate' class on scroll
 *
 * @param  {array} $elements         array of elements nodes
 * @param  {bool} once               plugin option
 * @return {void}
 */
const handleScroll = function ($elements, once) {
  const scrollTop = window.pageYOffset/countSacle();//console.log(scrollTop,'scrollTop')
  const windowHeight = window.innerHeight/countSacle();
  /**
   * Check all registered elements positions
   * and animate them on scroll
   */
  $elements.forEach((el) => {
    setState(el, windowHeight + scrollTop, once);
  });
};
//根据屏幕缩放计算缩放比例
const countSacle = function (){
  let screenWidth = document.body.clientWidth;
  let scaleX = Number((screenWidth / 1920).toFixed(2));
  return scaleX;
}

export default handleScroll;
