import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/explain',
    name: 'Explain',
    component: () => import('@/views/explain.vue')
  },
  // {
  //   path: '/product2',
  //   name: 'Home',
  //   component: () => import('@/components/product22.vue')
  // },
  // {
  //   path: '/product',
  //   name: 'Home',
  //   component: () => import('@/components/product.vue')
  // },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
