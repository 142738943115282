/**
 * Get offset of DOM element Helper
 * including these with translation
 *
 * @param  {Node} el [DOM element]
 * @return {Object} [top and left offset]
 */
const offset = function (el) {
  let _x = 0;
  let _y = 0;

  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - (el.tagName != 'BODY' ? el.scrollLeft : 0);
    _y += el.offsetTop - (el.tagName != 'BODY' ? el.scrollTop : 0);
    el = el.offsetParent;
  }

  return {
    top: _y,
    left: _x
  };
};
//根据屏幕缩放计算缩放比例
// const countSacle = function (){
//   let screenWidth = document.body.clientWidth;
//   let scaleX = Number((screenWidth / 1920).toFixed(2));
//   return scaleX;
// }
export default offset;
